.post-summary-image {
    max-width: 200px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
  }
  
  .post-summary {
    margin: 0.5rem;
  }
  
  .card {
    padding: 0.7rem;
    position: relative;
    transition: transform 0.3s ease-in-out;
  }

  
  .card:hover {
    transform: scale(1.03); /* Apply scale on hover */
  }
  
  .post-summary a {
    text-decoration: none;
  }
  
  .post-project .card {
    border: 2px solid #007bff;
  }
  
  .post-profile .card {
    border: 2px solid #6c757d;
  }
  
  .post-blog .card {
    border: 2px solid #17a2b8;
  }
  
  .badge {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0.5em 0.75em;
    font-size: 0.75em;
    color: white;
  }
  
  .badge-primary {
    background-color: #007bff !important;
  }
  
  .badge-secondary {
    background-color: #6c757d !important;
  }
  
  .badge-info {
    background-color: #17a2b8 !important;
  }
  