.full-post-image {
  max-width: 100%; 
  height: auto; 
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}

.inline-image {
  max-width: 90%; 
  height: auto; 
  display: block;
  margin: 10px auto; 
  border-radius: 10px; 
}

@media screen and (max-width: 576px) {
  .inline-image {
    max-width: 100%;
  }
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

.title {
  font-size: 2.8rem;
  font-weight: bold;
}

@media screen and (min-width: 370px) {
  h1 {
    font-size: 2.5rem;
  }
  
  h2 {
    font-size: 2rem;
  }
  
  h3 {
    font-size: 1.75rem;
  }
  
  h4 {
    font-size: 1.5rem;
  }
  
  .title {
    font-size: 3.3rem;
    font-weight: bold;
  }  
}

@media screen and (min-width: 370px) {
  h1 {
    font-size: 2.5rem;
  }
  
  h2 {
    font-size: 2rem;
  }
  
  h3 {
    font-size: 1.75rem;
  }
  
  h4 {
    font-size: 1.5rem;
  }
  
  .title {
    font-size: 3rem;
    font-weight: bold;
  }  
}

@media screen and (min-width: 470px) {
  h1 {
    font-size: 2.5rem;
  }
  
  h2 {
    font-size: 2rem;
  }
  
  h3 {
    font-size: 1.75rem;
  }
  
  h4 {
    font-size: 1.5rem;
  }
  
  .title {
    font-size: 4rem;
    font-weight: bold;
  }  
}

@media screen and (min-width: 992px) {
  h1 {
    font-size: 3.5rem;
  }
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 2.5rem;
  }
  h4 {
    font-size: 2rem;
  }
  .title {
    font-size: 4.5rem;
  }
}

@media screen and (min-width: 1200px) {
  h1 {
    font-size: 4.5rem;
  }
  h2 {
    font-size: 4rem;
  }
  h3 {
    font-size: 3rem;
  }
  h4 {
    font-size: 2.5rem;
  }
  .title {
    font-size: 5rem;
  }
}


.back-button {
  position: fixed;
  top: 20px;
  left: 20px;
  padding: 0;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-size: 2rem;
  line-height: 60px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform: translateY(-100px); /* Start hidden above the viewport */
  opacity: 0; /* Start invisible */
}

.back-button.visible {
  transform: translateY(0); /* Move into view */
  opacity: 1; /* Become visible */
}

.back-button::before {
  content: '<--';
  display: block;
  margin-top: -0.1em;
}

.back-button:hover {
  background-color: #333;
}

.back-button:active {
  background-color: #555;
}

.bottom-back-button {
  display: inline-block;
  margin-top: 18px;
  margin-bottom: 20px;
  padding: 0 10px;
  padding-bottom: 5px;
  font-size: 1.5rem;
  color: #ffffff;
  background-color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.bottom-back-button:hover {
  background-color: #333;
}



.publication-date {
  font-style: italic;
  color: #664;
  margin-bottom: 20px;
  text-align: right;
}

