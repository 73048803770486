/* InlineLoader.css */
.inline-loader {
    display: inline-flex;
    align-items: center;
    font-size: 10rem;
    color: #333;
  }
  
  .dot {
    animation: blink 1.4s infinite both;
    margin: 0 2px;
    font-size: 1.2rem;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes blink {
    0%, 80%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
  
  .loading-text {
    margin-left: 10px;
  }
  